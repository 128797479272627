@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
.body{
    font-family:  'Poppins', 'sans-serif';
    
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.p-20 {
    padding: 20px !important;
}

.fs-13{
  font-size: 13px !important;
}
.fs-14{
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.mt-20{
    margin-top: 20px !important;
}

.tableHeader{
    border: 1px solid #EEEEEE;
    margin-bottom: 10px !important;
    background-color: #E4E4E4;
}

.tableRow {
    margin-bottom: 5px;
    border: 1px solid #EEEEEE;

}

.pagination {
    border: 1px solid #E4E4E4;
    /* height: 50px; */
    align-items: center;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    margin-bottom: 20px;
}


.nav-text{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height */

letter-spacing: 0px;

color: #000000;
}

.right-nav-div{
    display: flex;
    justify-content: space-between;
    width: 60%;
}

.welcome-p{
    font-weight: 400;
    font-size:3.8vh;
    letter-spacing: 0px;
    font-style: normal;
    line-height: 40px;
}
.labridge-color{
    color:#3C9BD6 ;
}

/* Nav bar */
/* CustomNavbar.css */
.custom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.logo-img {
  width: 180px;
}

.custom-links {
  display: flex;
  align-items: center;
}

.custom-link {
  text-decoration: none;
  color: #333;
  margin-left: 20px;
  cursor: pointer;
  transition: color 0.3s;
}

.custom-link:hover {
  color: #007bff;
}

.custom-icon-wrapper {
  display: flex;
  align-items: center;
}

.balance-icon,
.logout-icon {
  font-size: 20px;
  margin-right: 5px;
}

.balance-text {
  display: none;
}

.logout-wrapper {
  background-color: #fff;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.logout-wrapper:hover {
  background-color: #f1f1f1;
}

@media (min-width: 768px) {
  .balance-text {
    display: inline;
  }
}


.onestop-div{
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
margin-right: 100px;
letter-spacing: 0px;
}

.ourservice-div{
    background: #F7F7F7;
    
    
  
}

.black-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
}

.blue-text{
color: #3C9BD6;

}

.missiondiv{
    display: flex;
    width: 100%;
 justify-content: space-evenly;
    
}


.addedstylecard{
    border-radius:16px;
    overflow:hidden;
    -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
    box-shadow:  15px 15px 27px #c1c1c4;
}

.cards-divmis{

    display: flex;
   justify-content: space-between;
}
.our-div{
    font-style: normal;
font-weight: 700;
font-size: 4.5vh;
line-height: 60px;
/* identical to box height */
padding-top: 2%;
margin-bottom: 0px !important;
letter-spacing: 0px;
}
.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border: 2px solid #E0E0E0;
  margin: 15px;
  height: 50px !important;
  padding: 10px;
  border-radius: 5px;
}

.pagination-controls button {
  margin: 0 10px;
}

.page-number {
  font-size: 16px;
  /* font-weight: ; */
}

.pagination-arrow {
  cursor: pointer;
  font-size: 20px;
  margin: 10px;
  /* border: 1px solid #E0E0E0; */
}

.pagination-arrow.disabled {
  color: gray;
  cursor: not-allowed;
}


.vission-card{
    background-color: #3C9BD6;
    color: white;
}
.whoweare-div{
background-image: url(../public//Whioweare_BG.png);
height: fit-content;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;

}

.corevalues-div{
    display: flex;
    justify-content: space-between;

}
.whoweare-content{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
}

.core-card{
background: #FFFFFF;
box-shadow: 0px 0px 4px #BADBE2;
border-radius: 10px;
display: flex;
/* flex-direction: column; */
justify-content: space-between;
/* align-items: center;
text-align: center; */
padding: 20px;
margin-top: 2%;
 
}

.transaction-card {
  padding: 20px;
  margin-top: 1%;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px #BADBE2;
  border-radius: 10px;
}

.whoweare-div1{
    display: flex;
    justify-content: space-between;
}
.service-card-maindiv{
    width: 100%;
    height:fit-content;
   
}

.service-div{
    color:#3C9BD6;
}

.tabnew{
    font-family: 'Poppins' !important;
    font-style: normal !important;
}

.service-card1{
    
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../public/researcher-female-lab.jpg);
    display:-webkit-box;
    display:-ms-flexbox;
  
    display: flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius:16px;
    overflow:hidden;
    -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
    box-shadow:  15px 15px 27px #c1c1c4;
    margin-top: 20px;
    height: 320px;
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.service-card2{
   
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../public//3672341.jpg);
    display:-webkit-box;
    display:-ms-flexbox;
  
    display: flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius:16px;
    overflow:hidden;
    -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
    box-shadow:  15px 15px 27px #c1c1c4;
    margin-top: 20px;
    height: 320px;
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-card3{
    
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../public/3746059.jpg);
    display:-webkit-box;
  display:-ms-flexbox;

  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius:16px;
  overflow:hidden;
  -webkit-box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow:  15px 15px 27px #c1c1c4;
  margin-top: 20px;
  height: 320px;
  background-position: center;
background-repeat: no-repeat;
background-size: cover;
   
}

.whoweareimg{
    width: 132%;
    background-image: url(../public/mask-group1@2x.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 42vh;

}

.whoweare-content{
    font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
letter-spacing: 0px;
}

.whoweare-button{
    width: 22vh;
    height:5.5vh;
    background: #79C9BC;
    border: none;
    color: white;
    font-size: 1.9vh;
    letter-spacing: 0px;


}

.service-card-maindiv a{
    color: white;
}

.top_section_input_container{
   
    padding: 10px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    /* sh */
    
    box-shadow: 0px 0px 9px rgba(60, 155, 214, 0.15);
    border-radius: 9px;
   
    width: 50%;
   
    flex-direction: row;

}
.input_container_location,
.input_container_search{
    padding: 5px;
    
}
.input_container_search{
    width:80%;
    border: none;
    outline: none;
}
.input_container_location{
    width: 35%;
    border-right: 2px solid #615d5d;
}

.labridge-p{
    font-weight: 700;
    font-size: 2vh;
    font-style: normal;
}
body {
  background: hsl(220deg, 10%, 97%);
  margin: 0;
  padding: 0;
}

.buttons-container {
  width: fit-content;
  margin-right: 5px;
  display: flex;
  align-items: center;
  
}

button {
  background: white;
  border: solid 2px black;
  padding: .375em 1.125em;
  font-size: 1rem;
}
.button-arounder1 {
  font-size: 1rem;
  background: rgb(75,74,75);
  color: hsl(190deg, 10%, 95%);
  
  box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, .2);
  transfrom: translateY(0);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  
  --dur: .15s;
  --delay: .15s;
  --radius: 16px;
  
  transition:
    border-top-left-radius var(--dur) var(--delay) ease-out,
    border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out,
    border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out,
    border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out,
    box-shadow calc(var(--dur) * 4) ease-out,
    transform calc(var(--dur) * 4) ease-out,
    background calc(var(--dur) * 4) steps(4, jump-end);
}
.button-arounder {
  font-size: 1rem;
  background: rgb(48,146,206);
  color: hsl(190deg, 10%, 95%);
  
  box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, .2);
  transfrom: translateY(0);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  
  --dur: .15s;
  --delay: .15s;
  --radius: 16px;
  
  transition:
    border-top-left-radius var(--dur) var(--delay) ease-out,
    border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out,
    border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out,
    border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out,
    box-shadow calc(var(--dur) * 4) ease-out,
    transform calc(var(--dur) * 4) ease-out,
    background calc(var(--dur) * 4) steps(4, jump-end);
}

.button-arounder1:hover,
.button-arounder1:focus {
  box-shadow: 0 4px 8px hsla(190deg, 15%, 5%, .2);
  transform: translateY(-4px);
  background: hsl(184, 25%, 21%);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
.button-arounder:hover,
.button-arounder:focus {
  box-shadow: 0 4px 8px hsla(190deg, 15%, 5%, .2);
  transform: translateY(-4px);
  background: hsl(184, 25%, 21%);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
.header-div{
    background-image: url(../public/BGimage.png);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;

}

.market-icon{
    display: flex;
    justify-content:space-between;
    width: 90%;
    padding-top: 4%;
    padding-bottom: 4%;
}

.market-ps{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: white;

}
.header-left-divs{
    padding-top:5%;
    padding-bottom: 5%;
    width: 38%;
  z-index: 10;
  margin-right: -10%;
}

.left-div-child{
    background: rgba(255, 255, 255, 0.47);
    height: 100%;
    
    
}

.header-right-divs{

width: 70%;


}
/* temp css */



/* styling for life style component*/

.special-offer-section {
  text-align: center;
  padding: 20px;
  /* Light saffron color */
}

.special-offer-title {
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
  color: #3c9bd6; /* Matching the theme color */
  margin-bottom: 20px;
}

.special-offer-images img {
  max-height: auto; /* Adjust this value to control the height */
  width: 55% !important;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .special-offer-images img {
    max-height: auto; /* Adjust this value to control the height */
    width: 80% !important;
  }
}

.special-offer-section .carousel-control-prev,
.special-offer-section .carousel-control-next {
  width: 5%; /* Increase the width of the control areas */
}

.special-offer-section .carousel-control-prev-icon,
.special-offer-section .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5); /* Darken the control icons */
  border-radius: 50%; /* Make them circular */
  padding: 10px; /* Add padding for better visibility */
}

.special-offer-section .carousel-control-prev-icon:hover,
.special-offer-section .carousel-control-next-icon:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker on hover */
}

.special-offer-section .carousel-indicators li {
  background-color: #f9a94a; /* Match the "Book Now" button color */
}

.special-offer-section .carousel-indicators .active {
  background-color: #d47c1f; /* Slightly darker for the active indicator */
}


.lifestyle-div{
    height: fit-content;
    background-image: url();
}

.package-div{
    background-image: url(../public/LifestyleBG.png);
    height: fit-content;
    background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
margin-top: 2%;
padding-bottom: 2%;
}

.lifepack-div1{
    background-image: url(../public/medical-researcher-using-laptop-while-measuring-sportsman-endurance-using-body-sensors-electrodes-mask-measuring-cardiac-rhythm\ 1.png);
    height: 36vh;
    width: 95%;
    background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.lifepack-div2{
   background-image: url(../public/construction-worker-pouring-concrete\ 1.png);
    height: 36vh;
    width: 95%;
    background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.lifepack-div3{
   background-image: url(../public/smile-corporate-skyscraper-teeth-bare.jpg);
    height: 36vh;
    width: 95%;
    background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.lifepack-div4{
   background-image: url(../public/young-male-psysician-with-patient-measuring-blood-pressure.jpg);
    height: 36vh;
    width: 95%;
    background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}

/* styling for life contactus component*/

.contactus-div{
    background-color:#3C9BD6;
    
}
.contactusmain-div{
    display: flex;
}

.contactus-inner-div{
    border-left: 5px solid #FFFFFF;
    border-right: 5px solid #FFFFFF;
    height: 100%;
   
    
}

.contactusimg-div{
    background-image: url(../public/medical-technology-medical-network\ 1.png);
    height:45.9vh;
    width: 41.5vh;
}


.cotactustxt{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 60px;
/* identical to box height */

letter-spacing: 0px;

color: #FFFFFF;

}

.market-div{
    background-color: #3C9BD6;
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.market-p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 36px;
color: #FFFFFF;
margin: 0px !important;
}

.explore-txt{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0px;
color: #FFFFFF;
}

.labeltxt{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
letter-spacing: 0px;
color: rgba(255, 255, 255, 0.5);
}

.input-field{
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
background-color: #3C9BD6;
border-top:none;
border-left:none;
border-right:none;
}
a{
    text-decoration:none !important; 
}
.mobilemain-div{
    background: color #f5f5f5; 
    height: fit-content;
}

.mobileappflex-div{
    display: flex;
    
    
}
.mobile-text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
/* or 188% */


/* Gray ( Paragraph ) */

color: #6C6C72;
}
.mobile-left{
    width: 50%;
}
.mobile-right{
    height: 100%;
    width: 50%;
}

.contactus-btn{
    background: #FFFFFF;
    border: none;
    height: 4.4vh;
    width: 20.6vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    
    letter-spacing: 0px;
    
    color: #3C9BD6;
}
.img-tag{
    height: 352.56px;
    width: 500px;

}

.footer-tag{
    display: flex;
    justify-content:space-between;
}




/* become partner*/
.become-proccessing-banner{
    background-image: url(../public//Become\ Processing\ Partner_BG.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 40vh;
    padding-top: 5%;
}

.form-div-1{
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
}
.labeltxt1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    color:rgba(0, 0, 0, 0.3);
    }
    
    .input-field1{
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    border-top:none;
    border-left:none;
    border-right:none;
    }

    .input-field1 :focus{
        border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;  
    width: 100% !important;
    border-top:none !important;
    border-left:none !important;
    border-right:none !important;
    }

.send-btn{
    background: #3C9BD6;
    border: none;
    height: 4.4vh;
    width: 20.6vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 4%;
    letter-spacing: 0px;
    
    color: #f5f5f5;
}
.become-div{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
/* identical to box height */
width: fit-content;
padding-right: 2%;
padding-bottom:0.8% ;
letter-spacing: 0px;

color: rgba(255, 255, 255, 0.9);
border-bottom:1px solid #FFFFFF;
}

/* Register-*/

.register-div > img{
    width:100%;
    height: 100vh;
}

.register-div{
    width: 25%;
}

.register-div2{
width: 32%;
}

.register-div3{
    width: 32%;
    }

.regbtn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  
    /* identical to box height */
    
  
    padding: 3% 18%;
    color: #FFFFFF;

border: 0px;
background: linear-gradient(90.2deg, #247CB3 1.51%, #3C9BD6 99.83%);
border-radius: 9px;
}    

.login h2,
.login p,
.login a {
  text-align: center;    
}

.login a {
text-decoration: none;  
font-size: .8em;
}

.login a:visited {
color: inherit;
}

.login a:hover {
text-decoration: underline;
}
.login input[type=submit] {
    border: none;
    background: #EF5350;
    color: white;
    font-weight: bold;  
    transition: 0.2s;
    margin: 20px 0px;
  }
  
  .login input[type=submit]:hover {
    background: #F44336;  
  }
  
  .login h2 {
    margin: 20px 0 0; 
    color: #0096E7;
    font-size: 28px;
  }
  
  .login p {
    margin-bottom: 20px;
  }
  .login {
    /* margin: 20px auto; */
    padding: 40px 50px;
    /* max-width: 300px; */
    border-radius: 5px;
    /* background: #fff; */
    /* box-shadow: 1px 1px 1px #666; */
  }
    .login input {
      width: 100%;
      display: block;
      box-sizing: border-box;
      margin: 10px 0;
      padding: 14px 12px;
      font-size: 16px;
      border-radius: 2px; 
      font-family: Raleway, sans-serif;
      height: 35px;
    }
  
  .login input[type=text],
  .login input[type=password] {
    border: 1px solid #c0c0c0;
    transition: .2s;
  }
  
  .login input[type=text]:hover {
    /* border-color: #F44336; */
    /* outline: none; */
    transition: all .2s ease-in-out;
  }
  .submitButton {
    background-color: #0096E7;
    color: #FFF;
    width: 100px;
    border: none;
    height: 35px;
    border-radius: 5px;
    margin-right: 10px;
  }  

  .bannerClass{
    width: 600px !important;
    /* height: 200px !important; */
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .imgClass {
    max-width: 100%; 
    max-height: 100%; 
    object-fit: contain;
  }


@media screen and (max-width: 950px) {
    .bannerClass {
        width: 400px !important;
        overflow: hidden;
    }
}

/* Login*/


.left-login-div{
    background-image: url(../public/1234.png);
    width: 50%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.forget-p{
    text-decoration: underline;
    color: #18608D;
}

.forget-p:hover{
    cursor: pointer;
}


/* book-test-onle*/

.bookonline-hero-img{
    background-image: url(../public/testbg.png);
    height:fit-content;
    width: 100%;
    padding: 5%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.booktest-hero-inner{
display: flex;
}
.lab-test-p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 48px;
letter-spacing: 0px;

color: #000000;

}
.comfort-right-div{
    background-image: url(../public/5.png);
   width: 50%;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.comfort-right-div1{
    background-image: url(../public/4289751.jpg);
   width: 50%;
    height: 32.4vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.comfort-div{
    width: 50%;
}

.div-search{
    width: 60%;
    margin-left: -6%;
}
.lab-test-big-p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size:50px;
    letter-spacing: 0px;
    color: #3C9BD6;
}

.card-main{
    width:250px;
    margin-top: 2%;
height: 519px;
background: #FFFFFF;
padding:1rem ;
}

.bookonline-div2{
    height: fit-content;
    width: 100vw;
    background-color: #F7F7F7;
    padding-top: 2%;
}

.text-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.order-div{
    background: #FFFFFF;
    border: 1px solid #3C9BD6;
    border-radius: 10px;
    box-shadow: 0px 0px 9px rgba(60, 155, 214, 0.15);
}

.order-div-head{
    height: 20%;
    background: linear-gradient(90.51deg, #18608D 21.87%, #3C9BD6 99.71%);
    padding: 1%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

.status-p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 14px;

color: #1B1B28;
}
.who-hero{
    background: url(../public/about\ us_BG.png);
    width: 100%;
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 48px;


color: #FFFFFF;

}
.div-left1{
   display: flex;

   flex-direction: column;
}
.flex-div{
    display: flex;
    flex: 1 1 0px;
}

.who-p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;


color: #1B1B28;
}

.who-p2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: #3C9BD6;
}

.who-p3{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;


color: #35363A;
}

.date-p{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
color: #818181;
}

.test-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #1B1B28;
}

.price-p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;

color: #1AAA2A;
}

.book-test-p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 48px;
/* identical to box height */

letter-spacing: 0px;

color: #000000;
}

.next-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    
    letter-spacing: 0px;
    
    color: #000000;  
}
.booktestlab-div1{
    display: flex;
    justify-content:space-between;
    margin-top: 4%;
}
.pd-p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 32px !important;
    margin-top: 2%;
    line-height: 48px;
    /* identical to box height */
    
  
    
    color: rgba(117, 117, 117, 0.9) !important;
      
}
.details-style {
  font-size: 22px ;
  font-weight: 600 ;
}
.total-div1{
    width: 357px;
display: flex;
justify-content: center;
align-items: center;



font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 32px;


color: #000000;



background: rgba(102, 202, 242, 0.3);

}
.total-div2{
    display: none;
}
.count-test{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 0.8rem;
line-height: 21px;
/* identical to box height */

letter-spacing: 0px;

color: #000000;
}
.warning-text{
    font-size: 16px;
    color: red;
}
.com-soon{
    font-size: 16px;
}

.bottom-div{
    background-color:#F7F7F7 ;
}

@media (max-width: 767px) {
  .desktop-view {
    display: none; /* Hide seconds-div on mobile */
  }
}

/* Media query for desktop view */
@media (min-width: 768px) {
  .mobile-view {
    display: none; /* Hide header-div on desktop */
  }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

ul {
  list-style: none;
}

input {
  overflow: hidden;
}

.section-title {
  position: relative;
  font-size: 30px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 0 0 35px;
}

.sec-pad {
  padding: 60px 0 0;
  margin: 50px 0;
}

.contact-sec {
  align-items: center;
  display: flex;
  background-color: #3C9BD6;
}

.contact-sec .contact-ul li,
.contact-ul b {
  font-size: 20px;
  margin: 10px 0;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  word-wrap: break-word;
}

.contact-sec .contact-ul i {
  font-size: 18px;
  padding: 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.contact-detail a {
  color: #000;
  text-decoration: none;
}

.contact-sec .contact-ul li b:hover {
  color: #f93;
}

.contact-sec .contact-ul li .fa-location-dot {
  color: #f44337;
  border: 2px solid #f4433790;
}

.contact-sec .contact-ul li .fa-phone {
  color: #00b055;
  border: 2px solid #00b05590;
}

.contact-sec .contact-ul li .fa-envelope {
  color: #ff6347;
  border: 2px solid #ff634790;
}

.contact-detail span {
  width: 400px;
  display: flex;
  justify-content: center;
}
.contact-detail span a {
  font-size: 20px;
  padding: 6px 12px;
  color: #000;
  border-radius: 50%;
  margin: 0px 5px;
}
.contact-detail span .fb {
  color: #3b5998;
  border: 3px solid #3b5998;
}
.contact-detail span .fb:hover {
  color: #fff;
  background-color: #3b5998;
}

.contact-detail span .insta {
  color: #833ab4;
  border: 3px solid #833ab4;
}
.contact-detail span .insta:hover {
  color: #fff;
  background-color: #833ab4;
}

.contact-detail span .twitter {
  color: #00acee;
  border: 3px solid #00acee;
}
.contact-detail span .twitter:hover {
  color: #fff;
  background-color: #00acee;
}

form.contFrm {
  max-width: 396px;
  margin: auto;
}

.inptFld {
  width: 100%;
  height: 50px;
  border: 0;
  margin: 0 0 10px;
  border-radius: 8px;
  padding: 0 20px;
  font-size: 16px;
  color: #000;
}

.inptFld:focus {
  outline-offset: -4px;
  outline: 1px solid #f93;
}

.contFrm textarea {
  height: 75px;
  padding-top: 5px;
}
.inptBtn {
  height: 50px;
  border: 0;
  background: #00b055;
  font-size: 14px;
  color: #fff;
  margin: auto;
  letter-spacing: 1px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
}

/* Responcive css Start */

@media (max-width: 991px) {
  .sec-pad {
    padding: 20px 0 0px;
  }

  .contact-sec .contact-ul li,
  .contact-ul b {
    font-size: 18px;
  }

  .contact-sec .contact-ul i {
    font-size: 14px;
    padding: 6px;
    margin-right: 6px;
  }

  .inptFld {
    height: 40px;
    margin: 0 0 10px;
    padding: 0 14px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .contact-detail span {
    width: auto;
  }
  .contact-detail span a {
    font-size: 18px;
    padding: 5px 10px;
    color: #000;
    border-radius: 50%;
    margin: 0px 5px 20px;
  }
}

@media (max-width: 575px) {
  .section-title {
    font-size: 26px;
    font-weight: 500;
  }
  .contact-sec {
    border-radius: 10% 10% 0% 0% / 5% 5% 0% 0%;
  }

  .contact-sec .contact-ul i {
    border: none;
  }
  .inptFld {
    height: 36px;
    margin: 0 0 8px;
    padding: 0 14px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .contact-sec .contact-ul li,
  .contact-ul b {
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {

    .header-right-divs{
        display: none;
    }
    .total-div1{
        display: none;
    }
    .left-login-div{
        display: none;
    }
    .div-right1{
    
    display: none;
  
    }
    .bookonline-hero-img{
      
        height:60vh;
      
    }

    .whoweare-img{
        display: none;
    }
  

    .corevalues-div{
        display: flex;
        flex-direction: column;
    
    }
    .market-icon{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .missiondiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .cards-divmis{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .comfort-right-div{
        display: none;
    }
    .comfort-div{
        width: 100%;
    }
    .total-div2{
        width: 357px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    
    
    color: #000000;
    
    
    
    background: rgba(102, 202, 242, 0.3);
    
    }

    .s-div{
      padding: 10%;
      background: #4d4a4a;
    }
    .booklabdiv{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .booktestlab-div1{
        display: block;
    }
    .header-div{
        height: fit-content;
    }
    .whoweareimg{
        display: none;
    }
    .contactusimg-div{
        display: none;
    }
    .contactus-inner-div{
        display: none;
    }
    .mobile-right{
        display: none;
    }
    .mobile-left{
        width: 100%;
    }
    .top_section_input_container{
   
        padding: 10px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        /* sh */
        
        box-shadow: 0px 0px 9px rgba(60, 155, 214, 0.15);
        border-radius: 9px;
       
        width: 100%;
       
        flex-direction: row;
    
    }
    .header-left-divs{
        padding-top:5%;
        padding-bottom: 5%;
        width: 100%;
      
      
    }
    .form-div-1{
        display: flex;
        flex-direction: column;
      
    }
    .mobile-right{
        display: none;
    }
    .right-nav-div {
      display: flex;
      justify-content: end !important;
      
    }
    .nav-text {
        font-size: 16px !important;
       }
  }

  @media only screen and (max-width: 1199px) {
    .nav-text {
     font-size: 1.2vw;
    }
  }







  /*Cards*/
  /* Font */


/* Design */
*,
*::before,
*::after {
  box-sizing: border-box;
}



.main1{
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
}

.img1 {
  height: 20vh;
  min-width: 17.03vw;
  vertical-align: middle;
}

.btn4{
    background: linear-gradient(90.2deg, #247CB3 1.51%, #3C9BD6 99.83%);
    color: white;
    border: none;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
/* identical to box height */

letter-spacing: 0px;
}

.transaction-header{
  font-weight: 500 !important;
  font-size: 25px;
  margin-top: 15px !important;
}

.btn5{
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    width: 50%;
    border: 1px solid #3C9BD6;
    color: #3C9BD6;
    background: white;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    /* identical to box height */

letter-spacing: 0px;
}

.cards1 {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item1 {
  display: flex;
  
}

.blue-div{
    padding: 2%;
    background: linear-gradient(90.51deg, #18608D 21.87%, #3C9BD6 99.71%);

}

.gold-p{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 1.4rem;
line-height: 36px;
/* identical to box height */

letter-spacing: 0px;

color: #FFFFFF;
}

.include-p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 30px;
    /* identical to box height */
    
    letter-spacing: 0px;
    
    color: #FFFFFF; 
}

.rating-div{
color: white;
background: #1AAA2A;
display: flex;
align-items: center;
justify-content: baseline;
width: fit-content;
}

.recom-div{
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    /* identical to box height */
    
    letter-spacing: 0px;
    
    color: #FFFFFF;
}

.c-div{
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    /* identical to box height */
    
    letter-spacing: 0px;
    
    color: #FFFFFF;
}

.card1 {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_content1 {
  
  background: #FFFFFF;
}

.card_title1 {
  color: black;
  font-size: 1rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  
  margin-top: 1rem;
}

.card_text1 {
  color: black;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
}


.white-div{
    background: #FFFFFF;
/* sh */
padding: 2%;
box-shadow: 0px 0px 9px rgba(60, 155, 214, 0.15);

}

.pg-1{
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    /* identical to box height */
    
    letter-spacing: 0px;
    
    color: #1B1B28;  
}

.pg-2{
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
   
    /* identical to box height */
    
    letter-spacing: 0px;
    
    color: #1B1B28;
}

.pg-3{
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    
    /* identical to box height */
    
    letter-spacing: 0px;
    
    color: #1AAA2A;
}

.div-3-view{
    padding: 2%;
    background: #FFFFFF;
/* sh */

box-shadow: 0px 0px 9px rgba(60, 155, 214, 0.15);
}
.btn8{
padding: 0.6rem 8rem;
background: linear-gradient(90.2deg, #247CB3 1.51%, #3C9BD6 99.83%);
border-radius: 9px;
border: none;
font-style: normal;
font-weight: 600;
font-size: 1rem;

/* identical to box height */

letter-spacing: 0px;

color: #FFFFFF;


}

.pd-p{
    font-style: normal;
font-weight: 500;
font-size: 1.25rem;
/* identical to box height */

letter-spacing: 0px;

color: #1B1B28;
}


.frq-p{
    font-style: normal;
font-weight: 500;
font-size: 1.25rem;
/* identical to box height */

letter-spacing: 0px;

color: #1B1B28;
}
.pdd-p{
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.8);
}

.add-detail-div{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
}






 /* card  */
 
 @media only screen and (min-width: 500px) {
   table{
      width:100%;
      table-layout: fixed;
    }
 }


  @media only screen and (max-width: 500px) {
    table{
      /* width:100%; */
      table-layout: fixed;
    } 
  }
  .tbl-header{
    background-color: #247CB3;
   }
  .tbl-content{
    height:400px;
    overflow-x:auto;
    margin-top: 0px;
    /* margin-bottom: 5%; */
    border: 2px solid #E0E0E0;
    border-radius: 10px;
  }
  .th1{
    padding: 20px 15px;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    color: #f5ecec;
    /* text-transform: uppercase; */
  }
  .td1{
    padding: 15px;
    text-align: left;
    vertical-align:middle;
    font-weight: 300;
    font-size: 16px;
    color: #1d1a1a;
    border-bottom: 1px solid #E0E0E0 !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  
  
  /* demo styles */
  
  
  
  
  /* follow me template */
  .made-with-love {
    margin-top: 40px;
    padding: 10px;
    clear: left;
    text-align: center;
    font-size: 10px;
    font-family: arial;
    color: #333030;
  }
  .made-with-love i {
    font-style: normal;
    color: #F50057;
    font-size: 14px;
    position: relative;
    top: 2px;
  }
  .made-with-love a {
    color: #9e2222;
    text-decoration: none;
  }
  .made-with-love a:hover {
    text-decoration: underline;
  }
  
  
  /* for custom scrollbar for webkit browser*/
  
  ::-webkit-scrollbar {
      width: 6px;
  } 
  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  } 
  ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  }


table,
td,
th {
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border: solid 1px;
  text-align: center;
}
.billingBtn {
  margin-top: 20px;
  border: none;
  width: 150px;
  height: 35px;
  border-radius: 5px;
  color: white;
  background-color: #4BB3EB
}

/* package listing */

/* src/App.css */



.container5 {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.package {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.package h2 {
  color: #333;
}

.package ul {
  list-style-type: none;
  padding: 0;
}

.package li {
  margin: 10px 0;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
  color: #007bff;
}

.pagination li.active {
  font-weight: bold;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.page-content {
  margin-top: 20px;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.test-list {
  list-style-type: none;
  padding: 0;
}

.test-item {
  margin: 10px 0;
}

.test-name {
  font-weight: bold;
}

.specimen-name {
  font-style: italic;
}

.suggestiondiv{
  cursor: pointer;
  margin-top: 10px;

}

