/* WelcomeMessage.css */
.welcome-message {
    font-size: 24px;
    text-align: center;
    padding: 20px;
    background-color: #007bff;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin: 20px auto;
  }
  
  .labname {
    font-weight: bold;
  }
  /* OrderHistoryTable.css */
.order-history-table {
    width: 100%;
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  @media (max-width: 768px) {
    .table-responsive {
      width: 100%;
      overflow-x: auto;
    }
  
    .table {
      width: auto;
      max-width: 100%;
      margin-bottom: 0;
      background-color: transparent;
      border-collapse: collapse;
      border-spacing: 0;
    }
  
    /* .table th,
    .table td {
      white-space: nowrap;
    } */
  }
  
  @media (max-width: 768px) {
    .welcome-message {
      font-size: 20px;
      padding: 15px;
    }
  }
  