.orders-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .order-card {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    width: 80%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .lab-name {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .error-remark {
    color: red;
    font-weight: bold;
  }
  
  .test-item {
    margin-top: 15px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
  
  .test-name {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .sample-type {
    font-size: 0.9rem;
    color: #555;
  }
  